import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

function scrollSection(selector, pushState = false) {
  if (!selector) {
    return;
  }
  const body = document.body;
  const section = body.querySelector(selector);
  const headerHeight = document.querySelector('#header').offsetHeight;
  const offsetY = headerHeight + 16;
  if (section) {
    gsap.to(window, {
      duration: 0.4,
      scrollTo: {
        y: section,
        offsetY: offsetY,
      }
    }).then(() => {
      if (pushState) {
        const newUrl = location.origin + location.pathname + selector;
        history.pushState(newUrl, null, newUrl);
      }
    })
  }
}

function handleLink(link) {
  const selector = link.getAttribute('href');
  scrollSection(selector, true);
}

const section = {
  init() {
    document.addEventListener('click', function(e) {
      const link = e.target.closest('.js-section-link');
      if (!link) {
        return;
      }
      e.preventDefault();
      handleLink(link);
    });
  },
  scrollSection: scrollSection
}

export default section;
