import axios from 'axios';
import gsap from 'gsap';
let form;

window.displayMailChimpStatus = function(data) {
  form.classList.remove('form-is-submitting');
	// Make sure the data is in the right format and that there's a status container
	if (!data.result || !data.msg || !mcStatus ) return;
	// Update our status message
	mcStatus.innerHTML = data.msg;
	// If error, add error class
	if (data.result === 'error') {
		mcStatus.classList.remove('success-message');
		mcStatus.classList.add('error-message');
		return;
	}
	// Otherwise, add success class
	mcStatus.classList.remove('error-message');
	mcStatus.classList.add('success-message');
};

function serialize(form) {
  // Setup our serialized data
  let serialized = '';
  // Loop through each field in the form
  for (let i = 0; i < form.elements.length; i++) {
    const field = form.elements[i];
    // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
    if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
    // Convert field data to a query string
    if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
      serialized += '&' + encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
    }
  }
  return serialized;
};

function submitMailchimp(e) {
  e.preventDefault();
  form = e.target;
  // Apply loading class
  form.classList.add('form-is-submitting');
  // Get the Submit URL
  let url = form.getAttribute('action');
  url = url.replace('/post?u=', '/post-json?u=');
  // Serialize the form data into a query string
  // Forked and modified from https://stackoverflow.com/a/30153391/1293256
  url += serialize(form) + '&c=displayMailChimpStatus';
  // Create script with url and callback (if specified)
	const ref = window.document.getElementsByTagName('script')[0];
	let script = window.document.createElement('script');
	script.src = url;
	// Create a global variable for the status container
	window.mcStatus = form.querySelector('.mc-status');
	// Insert script tag into the DOM (append to <head>)
	ref.parentNode.insertBefore(script, ref);
	// After the script is loaded (and executed), remove it
	script.onload = function() {
		this.remove();
	};
}

function submitContact(e) {
  e.preventDefault();
  form = e.target;
  const prevErrors = Array.from(form.querySelectorAll('.js-form-error'));
  if (prevErrors) {
    prevErrors.forEach(error => error.remove());
  }
  // Apply loading class
  form.classList.add('form-is-submitting');
  const data = new FormData(form);
  const url = form.getAttribute('action');
  const method = form.getAttribute('method');
  axios({
    method: method,
    url: url,
    data: data
  }).then((res) => {
    const { success } = res.data;
    const { errors } = res.data;
    if (errors) {
      Object.keys(errors).forEach((key) => {
        const field = form.querySelector(`[name="${key}"]`);
        if (field) {
          const div = document.createElement('div');
          div.classList.add('form__error', 'js-form-error');
          div.innerHTML = errors[key];
          field.parentElement.append(div);
        }
      })
    } else if (success) {
      form.classList.add('form-is-success');
      const thanks = document.querySelector('.js-thanks');
      const thanksMessage = Array.from(thanks.querySelectorAll('.js-thanks-message > *'));
      const tl = gsap.timeline();
      tl.add('start')
      .to(form, {
        opacity: 0,
        duration: 0.1,
      }, 'start')
      .set(thanks, {
        opacity: 1
      })
      .fromTo(thanksMessage, {
        y: 20,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
      });
    }
  }).catch((err) => {
    console.log(err);
  }).then(() => {
    form.classList.remove('form-is-submitting');
  })
}

export default function() {
  document.addEventListener('submit', function(e) {
    const target = e.target;
    if (target.id === 'mc-embedded-subscribe-form') {
      submitMailchimp(e);
    } else if (target.id === 'contact-form') {
      submitContact(e);
    }
  });
}
