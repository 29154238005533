import gsap from 'gsap';

const buttons = Array.from(document.querySelectorAll('.js-snippet-button'));

function initResizeListener() {
  let ticking = false;

  window.addEventListener('resize', (e) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        adjustActiveFaqs();
        ticking = false;
      });

      ticking = true;
    }
  })
}

function adjustActiveFaqs() {
  const active = Array.from(document.querySelectorAll('.snippet-is-active'));
  active.forEach(faq => {
    const answer = faq.querySelector('.js-snippet-code');
    gsap.set(answer, {height: 'auto'});
  })
}

function toggle(e) {
  const clicked = e.currentTarget;
  const parent = clicked.parentElement;
  const answer = parent.querySelector('.js-snippet-code');
  if (parent.classList.contains('snippet-is-active')) {
    parent.classList.remove('snippet-is-active');
    gsap.to(answer, {
      height: 0,
      duration: 0.1,
      ease: 'none'
    });
  } else {
    parent.classList.add('snippet-is-active');
    gsap.set(answer, {height: 'auto'});
    gsap.from(answer, {
      height: 0,
      duration: 0.1,
      ease: 'none',
    });
  }
}

export default function() {
  if (!buttons.length) {
    return;
  }
  buttons.forEach(button => {
    button.addEventListener('click', toggle);
  });
  initResizeListener();
}
