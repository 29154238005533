export default function() {
  const body = document.body;
  let timer;

  window.addEventListener('scroll', function() {
    clearTimeout(timer);

    if(!body.classList.contains('disable-hover')) {
      body.classList.add('disable-hover');
    }

    timer = setTimeout(function(){
      body.classList.remove('disable-hover');
    }, 250);
  }, false);
}
