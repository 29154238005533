import gsap from 'gsap';

const body = document.body;
const btn = document.querySelector('#mobile-nav-toggle');
const nav = document.querySelector('#mobile-nav');
const subMenuBtns = Array.from(document.querySelectorAll('.js-mobile-nav-show-submenu'));
const subMenuBackBtns = Array.from(document.querySelectorAll('.js-mobile-nav-hide-submenu'));
const mainContainer = document.querySelector('#mobile-nav-main');
const subMenuContainer = document.querySelector('#mobile-nav-submenus');
const accordBtns = Array.from(document.querySelectorAll('.js-mobile-nav-accord-button'));

function toggleAccord(e) {
  const clicked = e.currentTarget;
  const accordSelector = clicked.dataset.accord;
  const accord = document.getElementById(accordSelector);
  const parentGroup = accord.closest('.js-mobile-nav-submenu-group');
  if (parentGroup.classList.contains('mobile-nav-submenu-group-active')) {
    hideAccord(parentGroup, accord);
  } else {
    showAccord(parentGroup, accord);
  }
}

function showAccord(parentGroup, accord) {
  parentGroup.classList.add('mobile-nav-submenu-group-active');
  gsap.to(accord, {
    height: 'auto',
    duration: 0.2
  });
}

function hideAccord(parentGroup, accord) {
  parentGroup.classList.remove('mobile-nav-submenu-group-active');
  gsap.to(accord, {
    height: 0,
    duration: 0.2
  }); 
}

function toggleNav() {
  if (body.classList.contains('mobile-nav-is-active')) {
    hideNav();
  } else {
    showNav();
  }
}

function showNav() {
  gsap.to(nav, {
    xPercent: 100,
    duration: 0.1,
    opacity: 1
  });
  body.classList.add('mobile-nav-is-active');
}

function hideNav() {
  // Hide nav and reset submenus
  const tl = gsap.timeline();
  tl.to(nav, {
    xPercent: -100,
    duration: 0.1,
    opacity: 0,
    ease: 'none'
  })
  .set([mainContainer, subMenuContainer, '.js-mobile-nav-submenu'], {
    clearProps: 'all'
  });
  body.classList.remove('mobile-nav-is-active');
}

function showSubMenu(e) {
  const clicked = e.currentTarget;
  const subMenuSelector = clicked.dataset.submenu;
  const subMenu = document.getElementById(subMenuSelector);
  const firstGroup = subMenu.querySelector('.js-mobile-nav-submenu-group');
  const tl = gsap.timeline();
  
  tl.add('start')
  .set(subMenu, {
    width: '100%',
    height: 'auto'
  })
  .to(mainContainer, {
    xPercent: -100,
    duration: 0.1,
  }, 'start')
  .to(subMenuContainer, {
    xPercent: -100,
    duration: 0.1,
  }, 'start');

  if (firstGroup) {
    const firstAccord = firstGroup.querySelector('.js-mobile-nav-accord');
    tl.set(firstAccord, {
      height: 'auto'
    }, 'start')
    .call(() => {
      firstGroup.classList.add('mobile-nav-submenu-group-active');
    }, null, 'start');
    // Also set first accordian of each submenu to open by default each time
  }
}

function hideSubMenu(e) {
  const clicked = e.currentTarget;
  const subMenu = clicked.closest('.js-mobile-nav-submenu');
  const tl = gsap.timeline();
  tl.add('start')
  .to(mainContainer, {
    xPercent: 0,
    duration: 0.1,
    ease: 'none'
  }, 'start')
  .to(subMenuContainer, {
    xPercent: 100,
    duration: 0.1,
  }, 'start')
  .call(() => {
    const activeGroups = Array.from(subMenu.querySelectorAll('.mobile-nav-submenu-group-active:not(:first-of-type)'));
    if (!activeGroups.length) {
      return;
    }
    activeGroups.forEach((activeGroup) => {
      const activeAccord = activeGroup.querySelector('.js-mobile-nav-accord');
      hideAccord(activeGroup, activeAccord);    
    });
  }, null, 'start')
  .set(subMenu, {
    height: 0
  });
}

function init() {
  btn.addEventListener('click', toggleNav);
  subMenuBtns.forEach(btn => {
    btn.addEventListener('click', showSubMenu);
  });
  subMenuBackBtns.forEach(btn => {
    btn.addEventListener('click', hideSubMenu);
  });
  accordBtns.forEach(btn => {
    btn.addEventListener('click', toggleAccord);
  });
  // Close submenu if user clicks outside header/nav
  document.addEventListener('click', (e) => {
    const header = e.target.closest('#header');
    if (!header) {
      hideNav();
    }
  });
}

const mobileNav = {
  hideNav: hideNav,
  init: init
}

export default mobileNav;
