import section from './section';
const { scrollSection } = section;
import axios from 'axios';
import load from './load';
import nav from './nav';
import mobileNav from './mobile-nav';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
const body = document.body;

let stInstances = [];

function updateHistory(url) {
  history.pushState(url, null, url);
}

function updateMetaTitle(resHTML) {
  const headEl = resHTML.querySelector('head');
  const currentTitle = document.querySelector('title');
  const newTitle = headEl.querySelector('title');
  currentTitle.innerHTML = newTitle.innerHTML;
}

function ajax(options) {
  const { url, pushState = true, popState = false } = options;
  body.classList.add('page-is-loading');

  axios.get(url).then((res) => {
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    updateMetaTitle(resHTML);
    // Replace HTML
    const newContainer = resHTML.querySelector('#ajax-container');
    const newContent = newContainer.innerHTML;
    const container = document.querySelector('#ajax-container');
    container.innerHTML = newContent;
    // Check for script tag
    const scriptContainer = document.querySelector('.js-ajax-content-script');
    if (scriptContainer) {
      const script = scriptContainer.firstElementChild;
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.src = script.src;
      // Remove and re-add
      script.remove();
      container.append(newScript);
    }
    // Update history if pushState is true
    if (pushState) {
      updateHistory(url);
    }
    const images = Array.from(container.querySelectorAll('img'));
    if (images.length) {
      let loaded = 0;
      images.forEach(img => {
        img.onload = () => {
          if (loaded < images.length -1) {
            loaded++;
          } else {
            pageLoadAnimations();
          }
        };
        img.src = img.src;
      })
    } else {
      pageLoadAnimations();
    }
  }).catch((err) => {
    console.log(err);
    window.location.replace(url);
  })
}

function handlePopState(e) {
  e.preventDefault();
  if (!e.state && typeof event.state !== 'string') {
    return;
  }
  e.preventDefault();
  ajax({
    url: e.state,
    pushState: false,
    popState: true
  });
}

function newScrollTriggers() {
  stInstances = load.initScrollTriggers();
}

function pageLoadAnimations() {
  if (stInstances.length) {
    stInstances = stInstances.flat();
    stInstances.forEach(item => {
      item.kill();
    })
  }
  if (!location.hash) {
    ScrollTrigger.refresh();
    // Set scroll position to top
    window.scrollTo(0, 0);
    newScrollTriggers();
  } else {
    scrollSection(location.hash);
    newScrollTriggers();
  }
}

const page = {
  init() {
    // Store an initial state, so the back button works properly when returning
    // to the page you started on:
    history.replaceState(window.location.href, null, window.location.href);
    history.scrollRestoration = 'manual';
    window.addEventListener('popstate', handlePopState);
    document.addEventListener('click', function(e) {
      // Allow command-click and control-click to open new tab
      // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
      if (event.metaKey || event.ctrlKey) {
        return;
      }

      const clicked = e.target;
      const link = clicked.closest('a');
      // Check if internal link
      if (link && link.target !== '_blank') {
        const isPaginateLink = link.classList.contains('js-filter-paginate-link');
        const hrefString = link.getAttribute('href');
        const isFaqSection = link.href.includes('faqs#') && hrefString.startsWith('#');
        const isMapTool = link.href === 'https://openapparel.org/';
        const isAsset = link.href.includes('assets');
        // Then check is not paginate link, asset etc..
        if (isPaginateLink || isFaqSection || isMapTool || isAsset) {
          return;
        }
        mobileNav.hideNav();
        nav.hideSubMenu();
        // Always close mobile nav when navigating within site
        e.stopPropagation();
        e.preventDefault();
        ajax({url: link.href});
      }
    });
  },

  updateStInstances(items) {
    stInstances.push(items);
  }
}

export default page;
