let scrollPos = window.scrollY;

function initScrollListen() {
  let ticking = false;

  window.addEventListener('scroll', (e) => {
    scrollPos = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        checkScroll(scrollPos);
        ticking = false;
      });

      ticking = true;
    }
  })
}

function checkScroll(scrollPos) {
  const body = document.body;

  if (scrollPos > 0) {
    body.classList.add('page-is-scrolled');
  } else {
    body.classList.remove('page-is-scrolled');
  }
}

export default function() {
  checkScroll(scrollPos);
  initScrollListen();
}
