import axios from 'axios';
import gsap from 'gsap';
import page from './page';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { groupBy } from 'lodash';

const activeGroupClass = 'filter-group-is-active';

function initResizeListener() {
  let ticking = false;

  window.addEventListener('resize', (e) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        resizeContainer();
        ticking = false;
      });

      ticking = true;
    }
  })
}

function resizeContainer() {
  const ajaxContainer = document.querySelector('#ajax-cards');
  if (!ajaxContainer) {
    return;
  }
  gsap.set(ajaxContainer, {height: 'auto'});
  gsap.set(ajaxContainer, {height: ajaxContainer.offsetHeight});
  ScrollTrigger.refresh(true);
}

function updateHistory(url) {
  history.pushState(url, null, url);
}

function ajax(options) {
  const { url, pushState = true, popState = false, paginate = false } = options;
  const container = document.querySelector('#cards-container');
  container.classList.add('ajax-cards-is-loading');

  axios.get(url).then((res) => {
    // Replace HTML
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.querySelector('#ajax-cards');
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.querySelector('#ajax-cards');
    ajaxContainer.innerHTML = newContent;
    // Update history if pushState is true
    if (pushState) {
      updateHistory(url);
    }
    container.classList.remove('ajax-cards-is-loading')
    const items = Array.from(document.querySelectorAll('.js-ajax-card'));
    if (!items.length) {
      return;
    }
    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: container,
          offsetY: () => {
            const header = document.querySelector('#header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
    const newStInstances = ScrollTrigger.batch(items, {
      onEnter: (els) => {
        gsap.to(els, {
          y: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.1,
        });
      },
      once: true,
    });
    page.updateStInstances(newStInstances);
  });
}

function updateFilters() {
  const activeFilters = Array.from(document.querySelectorAll('.js-filter input:checked'));
  const groups = (groupBy(activeFilters, (inputEl) => {
    return inputEl.dataset.group;
  }));
  Object.keys(groups).forEach(group => {
    groups[group] = groups[group].map(inputEl => inputEl.value).join();
  });
  const searchParams = new URLSearchParams(groups);
  const href = window.location.href;
  const urlBase = href.includes('page') ? href.split('page')[0] : href.split('?')[0];
  const urlObj = new URL(urlBase);
  urlObj.search = searchParams;
  ajax({url: urlObj.href});
}

function paginate(link) {
  const url = link.href;
  ajax({
    url: url,
    paginate: true
  });
}

function hideGroup(active, resetMargin = false) {
  const container = document.getElementById('filters');
  const menu = active.querySelector('.js-filter-group-menu');
  active.classList.remove(activeGroupClass);

  const tl = gsap.timeline();
  tl.add('start')
  .to(menu, {
    height: 0,
    duration: 0.1
  }, 'start');

  if (resetMargin) {
    tl.to(container, {
      marginBottom: 0,
      duration: 0.1
    }, 'start');
  }
}

function toggleGroup(button) {
  const container = document.getElementById('filters');
  const parent = button.parentElement;
  const selector = button.dataset.groupMenu;
  const menu = document.getElementById(selector);
  const active = document.querySelector(`.${activeGroupClass}`);

  if (active && active === parent) {
    hideGroup(active, true);
    return;
  } else if (active && active !== parent) {
    hideGroup(active);
  }

  parent.classList.add(activeGroupClass);
  const tween = gsap.to(menu, {
    height: 'auto',
    duration: 0.2,
    paused: true
  }).progress(1);
  // Bit of a hack to get menu's end height ahead of time
  if (window.innerWidth >= 768) {
    gsap.to(container, {
      marginBottom: menu.offsetHeight,
      duration: 0.2
    });  
  }
  tween.progress(0).play();
}

function resetFilters() {
  const filters = Array.from(document.querySelectorAll('.js-filter input'));
  filters.forEach(filter => filter.checked = false);
  updateFilters();
}

export default function() {
  document.addEventListener('click', function(e) {
    const paginateLink = e.target.closest('.js-filter-paginate-link');
    const groupButton = e.target.closest('.js-filter-group-button');
    const resetButton = e.target.closest('#filters-reset');

    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    }

    if (groupButton) {
      e.preventDefault();
      toggleGroup(groupButton);
    }

    if (resetButton) {
      e.preventDefault();
      resetFilters();
    }
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      e.stopPropagation();
      updateFilters();
    }
  });

  initResizeListener();
}
