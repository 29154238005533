import load from './modules/load';
import page from './modules/page';
import mobileNav from './modules/mobile-nav.js';
import nav from './modules/nav.js';
import button from './modules/button.js';
import header from './modules/header.js';
import scroll from './modules/scroll.js';
import section from './modules/section.js';
import snippets from './modules/snippets.js';
import filters from './modules/filters.js';
import form from './modules/form.js';
import faqs from './modules/faqs.js';

page.init();
scroll();
mobileNav.init();
nav.init();
button();
header();
snippets();
filters();
section.init();
form();
faqs();

window.addEventListener('load', load.init);
