import gsap from 'gsap';

const body = document.body;
const navEl = document.querySelector('#nav');
const subMenuBtns = Array.from(document.querySelectorAll('.js-nav-submenu-button'));

function toggleSubMenu(e) {
  const clicked = e.currentTarget;
  const parent = clicked.parentElement;
  const activeParent = navEl.querySelector('.nav-submenu-is-active');
  if (activeParent && activeParent !== parent) {
    hideSubMenu(activeParent);
  }

  if (parent.classList.contains('nav-submenu-is-active')) {
    hideSubMenu(parent);
  } else {
    showSubMenu(parent);
  }
}

function showSubMenu(parent) {
  const subMenu = parent.querySelector('.js-nav-submenu');
  const tl = gsap.timeline();
  tl
  .set(subMenu, {
    height: 'auto',
    opacity: 1
  })
  .from(subMenu, {
    height: 0,
    opacity: 0,
    duration: 0.1,
    immediateRender: false
  })
  parent.classList.add('nav-submenu-is-active');
}

function hideSubMenu(parentEl) {
  const parent = parentEl ? parentEl : document.querySelector('.nav-submenu-is-active');
  if (!parent) {
    return;
  }
  const subMenu = parent.querySelector('.js-nav-submenu');
  gsap.to(subMenu, {
    height: 0,
    opacity: 0,
    duration: 0.05,
  });
  parent.classList.remove('nav-submenu-is-active');
}

const nav = {
  init() {
    subMenuBtns.forEach(btn => {
      btn.addEventListener('click', toggleSubMenu);
    });

    // Close submenu if user clicks outside header/nav
    document.addEventListener('click', (e) => {
      const header = e.target.closest('#header');
      if (!header) {
        hideSubMenu();
      }
    });
  },
  hideSubMenu: hideSubMenu
}

export default nav;
