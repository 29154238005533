import section from './section';
const { scrollSection } = section;
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function checkHash() {
  scrollSection(location.hash);
}

function initScrollTriggers() {
  document.body.classList.remove('page-is-loading', 'ajax-is-loading');
  const items = Array.from(document.querySelectorAll('.js-animate-in, .js-animate-in-children > *, .js-animate-stat'));
  if (!items.length) {
    return;
  }
  const stInstances = ScrollTrigger.batch(items, {
    onEnter: (els) => {
      const stats = els.filter(el => el.classList.contains('js-animate-stat'));
      const other = els.filter(el => !el.classList.contains('js-animate-stat'));

      if (stats) {
        const masterTl = gsap.timeline();

        stats.forEach(stat => {
          const tl = gsap.timeline();
          const numEl = stat.querySelector('.js-stat-number');
          const statObj = {val: 0}
          const statNum = parseInt(numEl.innerHTML.replaceAll(',', ''));
          tl.add('start')
          .to(statObj, {
            duration: 2,
            val: statNum,
            roundProps: 'val',
            onUpdate:function() {
              numEl.innerHTML = statObj.val.toLocaleString();
            },
          }, 'start')
          .to(stat, {
            y: 0,
            opacity: 1,
            duration: 0.5,
          }, 'start')
          masterTl.add(tl, '<10%')
        });
      }

      if (other) {
        gsap.to(other, {
          y: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.1,
        });
      }
    },
    once: true,
  });

  return stInstances;
}

const load = {
  init: () => {
    checkHash();
    initScrollTriggers();
  },
  initScrollTriggers: initScrollTriggers
}

export default load;
