import gsap from 'gsap';

const links = Array.from(document.querySelectorAll('a'));

function animate(e) {
  const link = e.currentTarget;
  const words = Array.from(link.querySelectorAll('.js-link-word'));
  if (!words.length) {
    return;
  }
  const tl = gsap.timeline();
  tl
  .to(words, {
    y: 8,
    opacity: 0,
    duration: 0.06,
    ease: 'none',
    stagger: 0.02,
  })
  .fromTo(words, {
    y: -8,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    duration: 0.06,
    delay: 0.2,
    stagger: 0.02,
    ease: 'back.out(2)',
    immediateRender: false
  })
}

export default function() {
  links.forEach(link => {
    // link.addEventListener('mouseenter', animate);
  });
}
