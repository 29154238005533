import gsap from 'gsap';

function initResizeListener() {
  let ticking = false;

  window.addEventListener('resize', (e) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        adjustActiveFaqs();
        ticking = false;
      });

      ticking = true;
    }
  })
}

function adjustActiveFaqs() {
  const active = Array.from(document.querySelectorAll('.faq-is-active'));
  if (!active.length) {
    return;
  }
  active.forEach(faq => {
    const answer = faq.querySelector('.js-faq-answer');
    gsap.set(answer, {height: 'auto'});
  })
}

function toggle(clicked) {
  const parent = clicked.parentElement;
  const answer = parent.querySelector('.js-faq-answer');
  if (parent.classList.contains('faq-is-active')) {
    parent.classList.remove('faq-is-active');
    gsap.to(answer, {
      height: 0,
      duration: 0.1,
      ease: 'none'
    });
  } else {
    parent.classList.add('faq-is-active');
    gsap.set(answer, {height: 'auto'});
    gsap.from(answer, {
      height: 0,
      duration: 0.1,
      ease: 'none',
    });
  }
}

export default function() {
  document.addEventListener('click', function(e) {
    const faq = e.target.closest('.js-faq-button');
    if (!faq) {
      return;
    }
    toggle(faq);
  });
  initResizeListener();
}
